<template>
  <div class="is-relative complete-offering-wrapper">
    <div
      v-if="currentStep === 2"
      class="notification is-success is-light has-text-dark preview-notif-wrapper flex-center-vertical mb-0 py-3 c-w-100
      is-justify-content-space-between is-align-items-center"
      :style="{top: `${heightNavbar}px`}">
      <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center c-w-50">
        <p class="averta-black label-20">Preview Penawaran</p>
        <p class="averta-regular-italic ml-1">(Periksa kembali penawaran sebelum dikirim ke client)</p>
      </div>
      <div style="gap: 1rem" class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-center c-w-50">
        <div v-if="currentStep > 1" @click="previousStep(currentStep)"
          class="is-flex is-flex-direction-row is-justify-content-center is-align-items-center is-clickable text-red averta-bold mr-4">
          <ArrowLeftIcon color="red" class="mr-2" />
          Sebelumnya
        </div>
        <button v-if="currentStep === 2" @click=" isSMRC ? handleOpenModalOffering(true) : previewOffering() " class="btn-download-offering is-flex is-align-items-center is-clickable">
          <DocumentPappersIcon color="#D9272D" class="mr-2" />
          <p class="averta-bold">Preview Penawaran</p>
        </button>
        <button v-if="currentStep === 2 && currentPath === 'complete-offering'" class="btn-kirim is-flex is-align-items-center is-clickable" @click="postPenawaran()">
          <p class="mr-2">Kirim</p>
          <CheckIcon />
        </button>
      </div>
    </div>

    <ModalPreviewOffering
    :dataPreview="dataPreview"
    :isHandleOpenModalOffering="isHandleOpenModalOffering"
    @close="isHandleOpenModalOffering = false"
    />

    <div class="c-w-100 min-h-80vh pt-5 ">
      <div class="is-flex is-align-items-center is-justify-content-space-between px-6 mb-5 mt-3">

        <div class="is-flex is-align-items-center is-clickable" @click="backToDetail()">
          <ArrowLeftIcon class="mr-3" />
          <p class="averta-bold is-size-5">Kembali ke Penawaran</p>
        </div>

        <div class="is-flex is-align-items-center">
          <!-- <span v-if="currentStep > 1" @click="previousStep(currentStep)" class="is-clickable text-red averta-bold mr-4">
            Sebelumnya
          </span> -->

          <div class="is-flex gap-3" v-if="currentStep === 1 && currentPath === 'complete-offering'">
            <button @click="showHideSidebar()" class="is-flex is-align-items-center is-clickable mr-3 btn-update py-3 px-5">
              <p class="averta-bold">Update Item Pekerjaan</p>
            </button>

            <button @click="nextStep(currentStep)"
              class="btn-kirim is-flex is-align-items-center is-clickable"
              :class="{'is-disabled': disableButton}"
              :disabled="disableButton"
            >
              <CheckIcon class="mr-2" />
              <p>Preview Penawaran</p>
            </button>
          </div>

          <!-- <button v-if="currentStep === 2 && currentPath === 'complete-offering'" class="btn-kirim is-flex is-align-items-center is-clickable" @click="postPenawaran()">
            <p class="mr-2">Kirim</p>
            <CheckIcon />
          </button> -->

          <div class="is-flex is-align-items-center" v-if="currentStep === 1 && currentPath === 'update-price'">
            <button @click="showHideSidebar()" class="is-flex is-align-items-center is-clickable mr-3 btn-update py-3 px-5">
              <p class="averta-bold">Update Item Pekerjaan</p>
            </button>

            <button
              @click="nextStep(currentStep)"
              class="btn-kirim is-flex is-align-items-center is-clickable"
              :class="{'is-disabled': disableButton}"
              :disabled="disableButton"
            >
              <CheckIcon class="mr-2" />
              <p>Preview Penawaran</p>
            </button>
          </div>

          <button v-if="currentStep === 2 && currentPath === 'update-price'" class="btn-kirim is-flex is-align-items-center is-clickable" @click="updateRejectPenawaran()">
            <p class="mr-2">Kirim</p>
            <CheckIcon />
          </button>

        </div>
      </div>

      <div class="notification is-light is-danger mx-6" v-if="message !== ''">
        <p>{{ message }}</p>
      </div>

      <div class="notification is-light is-danger mx-6" v-if="message_alfa !== ''">
        <button class="delete" @click="closeNotification"></button>
        <div class="content">
          <p class="label-16 averta-bold">{{ message_alfa.data.message }}</p>
          <ul>
            <li>
              <span class="averta-bold">{{ message_alfa.data.data.item_name }}</span>
              <ul>
                <li v-if="message_alfa.data.data.area_code !== null">
                  Area Code: {{ message_alfa.data.data.area_code }}
                </li>
                <li v-if="message_alfa.data.data.kanggo_price !== null">
                  Kanggo: {{ formatThousand(message_alfa.data.data.kanggo_price) }}
                </li>
                <li v-if="message_alfa.data.data.alfamart_price !== null">
                  Alfamart: {{ formatThousand(message_alfa.data.data.alfamart_price) }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <!-- Jenis Proyek -->
       <div class="mb-5 px-6 is-relative" v-if="currentStep === 1 && typeClient.list.length > 0">
        <p class="averta-black label-18 mb-3">Pilih Jenis Proyek <span class="text-merah">*</span></p>
        <!-- <div class="is-flex is-justify-content-space-between">
          <div v-for="type, index in typeClient.list" :key="index"
              class="wrap-type is-flex is-align-items-center"
              :class="{
                'is-active': selectTypeClient === type,
                'is-disabled': !typeClient.editable && !type.selected
              }"
          >
            <input type="radio"
              name="typeClient"
              class="mr-3 is-clickable"
              :id="type.code"
              :value="type"
              v-model="selectTypeClient"
              :disabled="!typeClient.editable && !type.selected"
              @change="disableButtonFunc"
            >
            <div>
              <p class="averta-black label-14">{{ type.name }}</p>
              <p>{{ type.description }}</p>
            </div>
          </div>
        </div> -->
        <div class="type-dropdown" :class='{ "is-disabled": currentPath === "update-price" }'>
          <div class="is-flex is-align-items-center is-justify-content-space-between">
            <div class="p-2">
              <p class="text-merah" v-if="selectTypeClient === null">
                <i>Belum ditentukan</i>
              </p>
              <div v-else>
                <div class="is-flex is-align-items-center">
                  <p class="averta-black label-14">{{ selectTypeClient.name }}</p>
                  <CheckIcon color="#10BD41" size="16" class="ml-2" />
                  <p class="averta-bold-italic label-14 color-green ml-1"><i>Saved</i></p>
                </div>
                <p class="color-gray">({{ selectTypeClient.description }})</p>
              </div>
            </div>
            <div
              v-if="currentPath !== 'update-price'"
              class="btn-dropdown is-flex is-align-items-center is-clickable"
              @click="dropdownTypeFunc"
            >
              <p class="averta-bold">{{ conditionalWordingDropdown }}</p>
              <ArrowFoward class="ml-3 arrow-icon" :class="{'is-rotate': showDropdownType}" />
            </div>
          </div>
          <div v-if="showDropdownType" class="mt-3 border-top">
            <div
              v-for="type, index in typeClient.list" :key="index"
              class="list-type mb-3 px-2 py-3 is-clickable"
              :class="{'mt-3': index === 0}"
              @click="selectTypeClientFunc(type)"
            >
              <div class="is-flex is-align-items-center">
                <p class="averta-black label-14">{{ type.name }}</p>
                <div v-if="selectTypeClient === type" class="is-flex is-align-items-center">
                  <CheckIcon color="#10BD41" size="16" class="ml-2" />
                  <p class="averta-bold-italic label-14 color-green ml-1"><i>Saved</i></p>
                </div>
              </div>
              <p class="color-gray label-14">{{ type.description }}</p>
            </div>
          </div>
        </div>
       </div>
      <!-- Jenis Proyek -->

      <div class="divider" v-if="currentStep === 1 && typeClient.list.length > 0"></div>

      <CompleteOffering class="px-6" @postLengkapiPenawaran="lengkapiPenawaran($event)" v-if="currentStep === 1 && !isLoading" :isFilled="isFilled" />
      <PreviewCompleteOffering class="px-6" v-if="currentStep === 2" @handleDataPreview="handleDataPreview" :isProjectUnitPrice="isProjectUnitPrice" />

      <b-sidebar type="is-light" :fullheight="true" :can-cancel="false" :fullwidth="true" :overlay="true" :right="true" v-model="showSidebar">
        <div v-if="!isLoading && sidebarMounted">
          <NewOffering @showHideSidebar="$event => showHideSidebar($event)" v-if="currentPath === 'update-price' || currentPath === 'complete-offering'" />
        </div>
      </b-sidebar>

      <b-loading :is-full-page="true" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency, alertErrorMessage, isSMRC } from '@/helpers'
import { ArrowLeftIcon, CheckIcon, SpinnerIcon, ArrowFoward, DocumentPappersIcon } from '@/components/icons'

import ModalPreviewOffering from '@/components/modal/ModalPreviewOffering.vue'
import CompleteOffering from './CompleteOffering.vue'
import PreviewCompleteOffering from './PreviewCompleteOffering.vue'
// import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import confirmOfferingImage from '@/assets/img/image-button-send-offering.png'
import successCreateImage from '../../../../../assets/img/survey-selesai.png'
import discardImage from '../../../../../assets/img/discard-data.png'
import NewOffering from '../../../project/NewOffering.vue'

export default {
  name: 'LengkapiPenawaran',
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataPenawaran: 'project/getDataDetailPenawaran',
      heightNavbar: 'dashboard/getNavbarHeight'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    },
    conditionalWordingDropdown () {
      if (this.showDropdownType) {
        return 'Tutup Pilihan'
      } else {
        if (this.selectTypeClient === null) {
          return 'Pilih Jenis'
        } else {
          return 'Ubah Jenis'
        }
      }
    },
    isSMRC () {
      return isSMRC(this.dataPreview.vendor_code || '')
    }
  },
  components: {
    ArrowLeftIcon,
    CheckIcon,
    CompleteOffering,
    PreviewCompleteOffering,
    SpinnerIcon,
    NewOffering,
    ArrowFoward,
    DocumentPappersIcon,
    ModalPreviewOffering
  },
  data () {
    return {
      currentStep: 1,
      dataLengkapiPenawaran: null,
      message: '',
      message_alfa: '',
      isLoading: false,
      showSidebar: false,
      typeClient: {
        list: [],
        editable: false
      },
      selectTypeClient: null,
      disableButton: false,
      sidebarMounted: false,
      surveyIdFromOffering: null,
      showDropdownType: false,
      isProjectUnitPrice: false,
      dataPreview: null,
      isHandleOpenModalOffering: false,
      isFilled: false
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x)
    },
    backToDetail () {
      if (this.currentPath === 'update-price') {
        this.$router.push(`/am/project/survey/${this.dataPenawaran.survey_id}/detail`)
      } else {
        this.$router.push(`/am/project/survey/${this.$route.params.surveyId}/detail`)
      }
    },
    previousStep (currentStep) {
      this.currentStep = currentStep - 1
    },
    nextStep (currentStep) {
      if (this.currentPath === 'complete-offering') {
        if (currentStep === 1) {
          if (this.dataLengkapiPenawaran === null) {
            this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
          } else {
            this.postLengkapiPenawaran()
          }
        }
      } else {
        this.updateHargaRejectPenawaran()
      }
    },
    lengkapiPenawaran (props) {
      this.dataLengkapiPenawaran = props.data || props
      if (props.is_use_unit_price) {
        this.isProjectUnitPrice = true
      } else {
        this.isProjectUnitPrice = false
        this.disableButtonFunc()
      }
    },
    async postLengkapiPenawaran () {
      let validate
      if (!this.isProjectUnitPrice) {
        this.dataLengkapiPenawaran.forEach(area => {
          area.components.forEach(components => {
            components.detail_components.forEach(detailComponent => {
              detailComponent.item.forEach(item => {
                item.workers.forEach(worker => {
                  if (worker.unit_price < 0 || worker.unit_price === '' || worker.unit_price === null) {
                    validate = 1
                  }
                })
                item.materials.forEach(material => {
                  if (material.unit_price < 0 || material.unit_price === '' || material.unit_price === null) {
                    validate = 1
                  }
                })
              })
            })
          })
        })
      }

      if (validate === 1) {
        this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
        return validate
      } else {
        this.message = ''

        // Change selected type client to true when selected
        this.selectTypeClient.selected = true

        const payload = {
          url: this.url,
          survey_id: this.$route.params.surveyId,
          data: this.dataLengkapiPenawaran,
          client_type: this.selectTypeClient
        }

        if (this.isProjectUnitPrice) {
          payload.is_use_unit_price = true
        }

        if (!this.isProjectUnitPrice) {
          await this.dataLengkapiPenawaran.forEach(area => {
            area.components.forEach(components => {
              components.detail_components.forEach(detailComponent => {
                detailComponent.item.forEach(item => {
                  item.workers.forEach(worker => {
                    worker.unit_price = +worker.unit_price.replace(/,/g, '')
                  })
                  item.materials.forEach(material => {
                    material.unit_price = +material.unit_price.replace(/,/g, '')
                  })
                })
              })
            })
          })
        }

        this.isLoading = true
        this.$store
          .dispatch('project/lengkapiPenawaran', payload)
          .then(response => {
            this.currentStep = 2
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            alertErrorMessage(error)
          })
      }
    },
    postPenawaran () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Penawaran?</p>',
        html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Kirim Penawaran',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId
          }

          this.isLoading = true
          this.$store
            .dispatch('project/postPenawaran', payload)
            .then(response => {
              this.isLoading = false
              this.backToDetail()
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
                  <p>Penawaran telah berhasil dibuat dan terkirim ke client. Mohon tunggu respon dari client untuk melanjutkan pemesanan</p>
                `,
                showCancelButton: true,
                reverseButtons: true,
                confirmButtonText: 'Lihat Detail Proyek',
                cancelButtonText: 'Lihat Daftar Proyek',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              }).then((result) => {
                this.isLoading = false
                if (result.isConfirmed) {
                  this.backToDetail()
                } else {
                  this.$router.push('/am/project')
                }
              })
            })
            .catch(error => {
              this.isLoading = false
              const dataError = error.response
              if (dataError) {
                this.message_alfa = dataError
              }
              alertErrorMessage(error)
            })
        }
      })
    },
    async updateHargaRejectPenawaran () {
      let validate
      this.dataLengkapiPenawaran.forEach(area => {
        area.components.forEach(components => {
          components.detail_components.forEach(detailComponent => {
            detailComponent.item.forEach(item => {
              item.workers.forEach(worker => {
                if (worker.unit_price < 0 || worker.unit_price === '' || worker.unit_price === null) {
                  validate = 1
                }
              })
              item.materials.forEach(material => {
                if (material.unit_price < 0 || material.unit_price === '' || material.unit_price === null) {
                  validate = 1
                }
              })
            })
          })
        })
      })

      if (validate === 1) {
        this.message = 'Pastikan harga tukang dan material sudah terisi dengan benar'
        return validate
      } else {
        await this.dataLengkapiPenawaran.forEach(area => {
          area.components.forEach(components => {
            components.detail_components.forEach(detailComponent => {
              detailComponent.item.forEach(item => {
                item.workers.forEach(worker => {
                  worker.unit_price = +worker.unit_price.replace(/,/g, '')
                })
                item.materials.forEach(material => {
                  material.unit_price = +material.unit_price.replace(/,/g, '')
                })
              })
            })
          })
        })

        this.$swal({
          imageUrl: confirmOfferingImage,
          imageAlt: 'confirm offering',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
          html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
          showCancelButton: true,
          confirmButtonText: 'Proses',
          cancelButtonText: 'Cek Kembali',
          reverseButtons: true,
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // Change selected type client to true when selected
            // this.selectTypeClient.selected = true

            const payload = {
              url: this.url,
              offering_id: this.$route.params.offeringId,
              data: this.dataLengkapiPenawaran
              // client_type: this.selectTypeClient
            }

            this.isLoading = true
            this.$store
              .dispatch('project/updateHargaSatuanPenawaranV2', payload)
              .then(response => {
                this.isLoading = false
                this.currentStep = 2
              })
              .catch(error => {
                this.isLoading = false
                if (error.response.data.code === 0) {
                  this.message = error.response.data.message
                }
                alertErrorMessage(error)
              })
          } else {
            this.dataLengkapiPenawaran.forEach(area => {
              area.components.forEach(components => {
                components.detail_components.forEach(detailComponent => {
                  detailComponent.item.forEach(item => {
                    item.workers.forEach(worker => {
                      worker.unit_price = this.formatPrice(worker.unit_price)
                    })
                    item.materials.forEach(material => {
                      material.unit_price = this.formatPrice(material.unit_price)
                    })
                  })
                })
              })
            })
          }
        })
      }
    },
    updateRejectPenawaran () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjut Proses Penawaran Ini?</p>',
        html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Proses',
        cancelButtonText: 'Cek Kembali',
        reverseButtons: true,
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            offering_id: this.$route.params.offeringId
          }

          this.isLoading = true
          this.$store
            .dispatch('projectV2/updateRejectPenawaran', payload)
            .then(response => {
              this.isLoading = false
              this.backToDetail()
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran berhasil dibuat</p>
                  <p>Penawaran telah berhasil dibuat dan terkirim ke client. Mohon tunggu respon dari client untuk melanjutkan pemesanan</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Lihat Detail',
                cancelButtonText: 'Lihat Daftar Proyek',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              })
                .then((result) => {
                  this.isLoading = false
                  if (result.isConfirmed) {
                    this.backToDetail()
                  } else {
                    this.$router.push('/am/project')
                  }
                })
            })
            .catch(error => {
              this.isLoading = false
              const dataError = error.response
              if (dataError) {
                this.message_alfa = dataError
              }
              alertErrorMessage(error)
            })
        }
      })
    },
    closeNotification () {
      this.message_alfa = ''
    },
    formatPrice (price) {
      if (price !== null) {
        price = price.toString().replace(/\D/g, '')
        const formattedPrice = Number(price).toLocaleString('en-US')
        return formattedPrice
      }
    },
    showHideSidebar (event) {
      if (event === 'success-update-item' || event === 'cancel-item') {
        this.getDetailPenawaran()
      }
      this.showSidebar = !this.showSidebar
      localStorage.removeItem('stepData')
    },
    getDetailPenawaran () {
      if (this.$route.params.offeringId) {
        this.isLoading = true
        const payload = {
          url: this.url,
          offering_id: this.$route.params.offeringId
        }

        this.$store
          .dispatch('project/getDetailPenawaran', payload)
          .then(response => {
            this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
              surveyId: response.data.data.survey_id
            })
            this.surveyIdFromOffering = response.data.data.survey_id
            this.getOfferingClientType()
            this.sidebarMounted = true
            this.isLoading = false
          })
          .catch(error => {
            this.isLoading = false
            alertErrorMessage(error)
          })
      }
    },
    getOfferingClientType () {
      let surveyId = null

      if (this.currentPath === 'complete-offering') {
        surveyId = this.$route.params.surveyId
      } else {
        surveyId = this.surveyIdFromOffering
      }

      const payload = {
        url: this.url,
        survey_id: surveyId
      }

      this.isLoading = true

      this.$store
        .dispatch('project/getOfferingClientType', payload)
        .then(response => {
          this.typeClient = response.data.data
          const selectedType = response.data.data.list.find((item) => item.selected)
          if (selectedType) {
            this.selectTypeClient = selectedType
          }
          this.disableButtonFunc('initTypeClient')
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    disableButtonFunc (type) {
      let validateData = false
      let validateTypeClient = false
      if (type !== 'initTypeClient') {
        this.dataLengkapiPenawaran.forEach(area => {
          area.components.forEach(components => {
            components.detail_components.forEach(detailComponent => {
              detailComponent.item.forEach(item => {
                item.workers.forEach(worker => {
                  if (worker.unit_price < 0 || worker.unit_price === '' || worker.unit_price === null) {
                    validateData = true
                  }
                })
                item.materials.forEach(material => {
                  if (material.unit_price < 0 || material.unit_price === '' || material.unit_price === null) {
                    validateData = true
                  }
                })
              })
            })
          })
        })
      }

      if (this.typeClient.list.length < 1) { // Kondisi untuk data lama yang tidak ada tipe client dan penawarannya direject
        validateTypeClient = false
      } else if (this.selectTypeClient === null) { // Kondisi jika tipe client belum dipilih
        validateTypeClient = true
      }

      if (validateData === true || validateTypeClient === true) {
        this.disableButton = true
      } else {
        this.disableButton = false
      }
    },
    dropdownTypeFunc () {
      this.showDropdownType = !this.showDropdownType
    },
    selectTypeClientFunc (type) {
      this.selectTypeClient = type
      this.showDropdownType = false
      this.disableButtonFunc()
    },
    handleOpenModalOffering (value) {
      this.isHandleOpenModalOffering = value
    },
    previewOffering () {
      const path = `/am/offering-project/${this.dataPreview.offering_id}`
      const query = { preview: true }
      const routeData = this.$router.resolve({ path, query })
      window.open(routeData.href, '_blank')
    },
    handleDataPreview (data) {
      this.dataPreview = data
    }
  },
  async created () {
    await this.getDetailPenawaran()
    if (this.currentPath === 'complete-offering') {
      this.getOfferingClientType()
    }
    if (this.currentPath === 'update-price') {
      this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
        isUpdate: true
      })
    }
    //  else {
    //   this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
    //     clearData: true
    //   })
    // }
  },
  beforeRouteLeave (to, from, next) {
    if (this.showSidebar === true && this.currentStep !== 5 && !this.isBS && this.currentPath !== 'update-price' && this.currentPath !== 'complete-offering') {
      next(false)
      this.$swal({
        width: '600px',
        padding: '10px 10px 30px 10px',
        html: `
          <img src="${discardImage}" class="img-create-penawaran mb-4" />
          <p class="averta-bold mb-4">Keluar Dari Halaman Ini?</p>
          <p class="averta-reguler">Informasi yang telah diedit akan hilang</p>
        `,
        showCancelButton: true,
        confirmButtonText: 'Keluar',
        cancelButtonText: 'Tetap Dihalaman Ini',
        customClass: {
          confirmButton: 'btn-batal-detail-component',
          cancelButton: 'btn-hapus-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          localStorage.removeItem('stepData')
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId // ini harus ada agar id survey yang di ambil dari vuex
          }
          this.$store
            .dispatch('projectV2/cancelOffering', payload)
            .then(() => {
              this.showHideSidebar('cancel-item')
              next()
            })
            .catch((error) => {
              alertErrorMessage(error)
            })
        }
      })
    } else {
      next()
    }
  },
  watch: {
    currentStep (newVal, oldVal) {
      if (newVal === 1) {
        this.selectTypeClient = null
        this.getDetailPenawaran()
        this.getOfferingClientType()
        if (this.currentPath === 'update-price') {
          this.$store.commit('project/setDataUpdateItemPekerjaanBS', {
            isUpdate: true
          })
        }
      }
      if (oldVal === 2) {
        this.isFilled = true
      }
    },
    surveyIdFromOffering (newVal) {
      if (newVal) {
        this.getOfferingClientType()
      }
    }
  }
}
</script>

<style scoped>
.complete-offering-wrapper {
  width: calc(100% - 75px);
}

.btn-download-offering {
  background: transparent;
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: #D9272D;
  padding: 8px 24px;
  border: 1px solid #D9272D;
  max-height: 3em;
}

.btn-kirim {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 8px 24px;
  border: none;
  max-height: 3em;
}

.btn-kirim.is-disabled {
  background: #9A9A9A !important;
  cursor: not-allowed !important;
}

.text-red {
  color: #EB4600;
}

.btn-update {
  background: white;
  color: #D9272D;
  border: 2px solid #D9272D;
  border-radius: 24px;
}

.preview-notif-wrapper {
  position: sticky;
  z-index: 5;
}

.wrap-type {
  background: #FAFAFA;
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  padding: 16px;
  width: 49.5%;
}

.wrap-type.is-active {
  background: #E1EEFF;
  border: 1px solid #3185FC;
}

.wrap-type.is-disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.wrap-type.is-disabled input[type=radio] {
  cursor: not-allowed !important;
}

.wrap-type input[type=radio] {
  height: 20px;
  width: 20px;
}

.divider {
  border-bottom: 8px solid #E1E1E1;
  margin: 24px 0px;
}

.type-dropdown {
  border: 1px solid #E1E1E1;
  border-radius: 16px;
  padding: 10px 24px 10px 16px;
  width: 100%;
}

.type-dropdown.is-active {
  position: absolute;
  z-index: 10;
  top: 40px;
  /* left: 0px; */
  width: 94%;
  background: white;
}

.type-dropdown.is-disabled {
  cursor: not-allowed !important;
  background: #F5F5F5;
}

.btn-dropdown {
  border: 1px solid #E10009;
  border-radius: 100px;
  color: #E10009;
  padding: 6px 24px;
}

.arrow-icon {
  rotate: 90deg;
}

.arrow-icon.is-rotate {
  rotate: -90deg;
}

.list-type {
  border-radius: 12px;
}

.list-type:hover {
  background: #FCECEC;
}

.list-type.is-active {
  background: #FCECEC;
}

.border-top {
  border-top: 1.5px solid #E1E1E1;
}
</style>
