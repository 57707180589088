<template>
  <div>

    <!-- STICKY HEADER -->
    <div class="sticky-header" :style="{ top: navbarPosition }">
      <div class="is-flex is-align-items-center ml-sm-1">
        <div class="is-flex is-align-items-center is-clickable" @click="goBack">
          <ArrowLeftIcon class="mr-3" />
        </div>
        <div class="is-flex is-align-items-center justify-content-sm-between flex-sm-column align-items-sm-start">
          <span class="mr-3 averta-bold label-18 label-sm-14">{{ conditionalIDProject }} | {{ dataHeader.store_name }} {{ dataProps.store.unit_name ? `- ${dataProps.store.unit_name}` : '' }}</span>
          <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true" v-if="dataHeader.status === 'Menunggu Konfirmasi'">
            <template v-slot:content>
              <div class="is-flex is-align-items-center wrapper-tooltip">
                <i class="mdi mdi-information mr-3"></i>
                <span>{{ dataHeader.tooltips }}</span>
              </div>
            </template>
            <div class="px-4 py-1 py-sm-0 averta-bold is-flex is-align-items-center status-survey" :class="handleGetStatusClass(dataHeader.status)">
              <div class="is-flex is-align-items-center">
                <span class="label-12 label-sm-10">{{ wordingHelper(dataHeader.status) }}</span>
                <b-icon icon="information ml-3"></b-icon>
              </div>
            </div>
          </b-tooltip>
          <div v-else class="px-4 py-1 py-sm-0 averta-bold is-flex is-align-items-center status-survey" :class="handleGetStatusClass(dataHeader.status)">
            <span class="label-12 label-sm-10">{{ wordingHelper(dataHeader.status) }}</span>
          </div>
        </div>
      </div>
      <div class="is-hidden-mobile">
        <!-- Konsultan -->
        <div v-if="isKonsultan" class="is-flex is-justify-content-end">
          <ButtonApp @click="onCheckin" :isSecondary="true" :is-bold="true" v-if="dataHeader.can_check_in == true" class="is-hidden-mobile">
            <p class="py-2">Check-In Pemeriksaan</p>
          </ButtonApp>
          <ButtonApp @click="onFinishSurvey" :is-bold="true" v-if="dataHeader.can_complete_survey == true" class="is-hidden-mobile ml-3">
            <p class="py-2">Selesaikan Survey</p>
          </ButtonApp>
          <ButtonApp @click="onCreateOffering" :is-bold="true" v-if="dataHeader.can_create_offering == true" class="is-hidden-mobile">
            <p class="py-2">+ Buat Penawaran</p>
          </ButtonApp>
        </div>

        <!-- SM -->
        <div v-if="isSM" class="is-flex is-flex-direction-column is-align-items-flex-end">
          <div class="is-flex is-justify-content-end">
            <ButtonApp v-if="canUpdate" :isSecondary="true" :is-bold="true" @click="handleUpdatePenawaran" class="mr-3">
              <p style="padding: 5px">Update Penawaran</p>
            </ButtonApp>
            <ButtonApp v-if="canSubmit" :is-bold="true" @click="onProceedToPurchasing">
              <p style="padding: 5px">Lanjutkan ke Purchasing</p>
            </ButtonApp>
          </div>
          <div v-if="isFromBS" :class="{'mt-3': canUpdate && canSubmit}" class="is-flex gap-3">
            <ButtonApp :is-bold="true" :isSecondary="true" v-if="isSM || isBS"
            @click="dataSurvey.detail.worker_id ?
            handleOpenModalsCantCancelSurvey() : handleOpenModalsCancelSurvey()">
              <p style="padding: 5px">Batalkan Survey</p>
            </ButtonApp>
            <ButtonApp :is-bold="true" @click="handleUpdateReschedule">
              <p style="padding: 5px">Reschedule Survey Proyek</p>
            </ButtonApp>
          </div>
        </div>

        <!-- BS -->
        <div v-if="isBS" class="is-flex is-align-items-flex-end">
          <div v-if="isFromBS" class="is-flex gap-3">
            <ButtonApp :is-bold="true" :isSecondary="true" v-if="isSM || isBS"
            @click="dataSurvey.detail.worker_id ?
            handleOpenModalsCantCancelSurvey() : handleOpenModalsCancelSurvey()">
              <p style="padding: 5px">Batalkan Survey</p>
            </ButtonApp>
            <ButtonApp :is-bold="true" @click="handleUpdateReschedule">
              <p style="padding: 5px">Reschedule Survey Proyek</p>
            </ButtonApp>
          </div>
          <div v-if="dataHeader.button_offering_expired" class="ml-2">
            <ButtonApp :isSecondary="true" :is-bold="true" @click="handleExpired">
              <p style="padding: 5px">Jadikan Penawaran Expired</p>
            </ButtonApp>
          </div>
          <div v-if="dataHeader.button_offering_client" class="ml-2">
            <ButtonApp :is-bold="true" @click="isSMRC ? handleModalLihatPenawaran(true) : onPreviewPenawaranClient(true)">
              <p style="padding: 5px">Lihat Penawaran Client</p>
            </ButtonApp>
          </div>
          <div v-if="isOffering" class="ml-2 is-flex gap-3">
            <div v-if="dataPenawaran.can_create_project === true" class="is-flex gap-3">
              <ButtonApp :is-bold="true" @click="onCreateOrderProject">
                <p style="padding: 5px">Buat Order Proyek</p>
              </ButtonApp>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="dataHeader" class="c-w-sm-100">
      <div class="is-flex is-align-items-center is-justify-content-space-between mb-5 mt-3 px-lg-6 flex-sm-column align-items-sm-start is-hidden-mobile">
        <div class="is-flex is-align-items-center is-clickable" @click="goBack">
          <ArrowLeftIcon class="mr-3" />
          <p class="averta-bold is-size-5">Kembali</p>
        </div>
        <RedirectClient :vendor="dataHeader.vendor" />
      </div>

      <div class="is-flex is-justify-content-space-between is-align-items-flex-start mb-3 mb-sm-0 flex-sm-column align-items-sm-start">
        <div class="is-flex is-align-items-center c-w-sm-100 mr-4">
          <div class="is-hidden-desktop is-hidden-tablet" @click="goBack">
            <ArrowLeftIcon class="mr-3" />
          </div>
          <div class="header-body-wrapper thin-scroll">
            <p v-if="isProject">ID Proyek</p>
            <div class="is-flex is-align-items-flex-start">
              <span class="mr-3 averta-bold label-24 label-sm-16">{{ conditionalIDProject }} | {{ dataHeader.store_name }} {{ dataProps.store.unit_name ? `- ${dataProps.store.unit_name}` : '' }}</span>
              <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true" v-if="dataHeader.status === 'Menunggu Konfirmasi'">
                <template v-slot:content>
                  <div class="is-flex is-align-items-center wrapper-tooltip">
                    <i class="mdi mdi-information mr-3"></i>
                    <span>{{ dataHeader.tooltips }}</span>
                  </div>
                </template>
                <div class="px-4 py-1 averta-bold is-hidden-mobile is-flex is-align-items-center status-survey mt-1 flex-shrink-0" :class="handleGetStatusClass(dataHeader.status)">
                  <div class="is-flex is-align-items-center">
                    <span class="is-size-7">{{ wordingHelper(dataHeader.status) }}</span>
                    <b-icon icon="information ml-3"></b-icon>
                  </div>
                </div>
              </b-tooltip>
              <div v-else class="px-4 py-1 averta-bold is-hidden-mobile is-flex is-align-items-center status-survey mt-1 flex-shrink-0" :class="handleGetStatusClass(dataHeader.status)">
                <span class="is-size-7">{{ wordingHelper(dataHeader.status) }}</span>
              </div>
            </div>
            <!-- Tag Redirect -->
            <div
              v-if="dataHeader.project_complain && dataHeader.project_complain.project_parent_id"
              class="tag-redirect is-flex is-align-items-center is-justify-content-center"
              :class="{'is-clickable': isBS || isSM, 'is-disabled': !isBS && !isSM}"
              @click="redirectToProjectParent"
            >
              <TagRedirect class="mr-3" :color="!isBS && !isSM && '#868686'"/>
              <p class="mr-3">
                <span class="averta-bold label-16 text-grey">ID Terkait : </span>
                <span
                  class="averta-bold label-16"
                  :class="{
                    'text-grey' : !isBS && !isSM,
                    'text-merah' : isBS || isSM
                  }"
                >
                  {{ dataHeader.project_complain.survey_parent_id }}
                </span>
              </p>
              <ArrowRightIcon color="#E10009" width="13" height="13" v-if="isBS || isSM" />
            </div>
            <!-- Tag Redirect -->
            <p class="mt-1 white-space-nowrap">Cabang {{ dataHeader.branch_name }}
              {{ isOffering || isProject ? ' • Tanggal Pembuatan Penawaran ' : ' • Tanggal Survey : ' }} <span class="averta-bold">{{ convertDate(isOffering || isProject ? dataHeader.created_at : dataHeader.survey_date) }}</span>
            </p>

            <!-- FOR DEKSTOP -->
            <div class="is-hidden-mobile">
              <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true" v-if="dataHeader.status === 'Menunggu Konfirmasi'">
                <template v-slot:content>
                  <div class="is-flex is-align-items-center wrapper-tooltip">
                    <i class="mdi mdi-information mr-3"></i>
                    <span>{{ dataHeader.tooltips }}</span>
                  </div>
                </template>
                <div class="px-4 py-1 averta-bold is-hidden-desktop is-hidden-tablet is-inline-block status-survey" :class="handleGetStatusClass(dataHeader.status)">
                  <div class="is-flex is-align-items-center">
                    <span>{{ wordingHelper(dataHeader.status) }}</span>
                    <b-icon icon="information ml-3"></b-icon>
                  </div>
                </div>
              </b-tooltip>
              <div v-else class="px-4 py-1 averta-bold is-hidden-desktop is-hidden-tablet is-inline-block status-survey" :class="handleGetStatusClass(dataHeader.status)">
                <span>{{ wordingHelper(dataHeader.status) }}</span>
              </div>
            </div>

            <!-- FOR MOBILE -->
            <div class="is-hidden-tablet is-hidden-dekstop mb-3">
              <b-tooltip position="is-bottom" type="is-dark" :triggers="['hover']" :auto-close="true" v-if="dataHeader.status === 'Menunggu Konfirmasi'">
                <template v-slot:content>
                  <div class="is-flex is-align-items-center wrapper-tooltip">
                    <i class="mdi mdi-information mr-3"></i>
                    <span>{{ dataHeader.tooltips }}</span>
                  </div>
                </template>
                <div class="px-4 py-1 averta-bold is-hidden-desktop is-hidden-tablet is-inline-block status-survey" :class="handleGetStatusClass(dataHeader.status)">
                  <div class="is-flex is-align-items-center">
                    <span>{{ wordingHelper(dataHeader.status) }}</span>
                    <b-icon icon="information ml-3"></b-icon>
                  </div>
                </div>
              </b-tooltip>
              <div v-else class="px-4 py-1 averta-bold is-hidden-desktop is-hidden-tablet is-inline-block status-survey" :class="handleGetStatusClass(dataHeader.status)">
                <span>{{ wordingHelper(dataHeader.status) }}</span>
              </div>
            </div>

          </div>
        </div>

        <!-- Konsultan -->
        <div v-if="isKonsultan" class="is-flex is-justify-content-end">
          <ButtonApp @click="onCheckin" :isSecondary="true" :is-bold="true" v-if="dataHeader.can_check_in == true" class="is-hidden-mobile">
            <p class="py-2">Check-In Pemeriksaan</p>
          </ButtonApp>
          <ButtonApp @click="onFinishSurvey" :is-bold="true" v-if="dataHeader.can_complete_survey == true" class="is-hidden-mobile ml-3">
            <p class="py-2">Selesaikan Survey</p>
          </ButtonApp>
          <ButtonApp @click="onCreateOffering" :is-bold="true" v-if="dataHeader.can_create_offering == true" class="is-hidden-mobile">
            <p class="py-2">+ Buat Penawaran</p>
          </ButtonApp>
        </div>

        <!-- SM -->
        <div v-if="isSM" class="is-flex is-flex-direction-column is-align-items-flex-end is-hidden-mobile">
          <div class="is-flex is-justify-content-end">
            <ButtonApp v-if="canUpdate" :isSecondary="true" :is-bold="true" @click="handleUpdatePenawaran" class="mr-3">
              <p style="padding: 5px">Update Penawaran</p>
            </ButtonApp>
            <ButtonApp v-if="canSubmit" :is-bold="true" @click="onProceedToPurchasing">
              <p style="padding: 5px">Lanjutkan ke Purchasing</p>
            </ButtonApp>
          </div>
          <div v-if="isFromBS" class="mt-3 mt-sm-0 mb-sm-2 ml-sm-2 is-flex gap-3">
            <ButtonApp :is-bold="true" :isSecondary="true" v-if="isSM || isBS"
            @click="dataSurvey.detail.worker_id ?
            handleOpenModalsCantCancelSurvey() : handleOpenModalsCancelSurvey()">
              <p style="padding: 5px">Batalkan Survey</p>
            </ButtonApp>
            <ButtonApp :is-bold="true" @click="handleUpdateReschedule">
              <p style="padding: 5px" >Reschedule Survey Proyek</p>
            </ButtonApp>
          </div>
        </div>

        <!-- BS -->
        <div v-if="isBS" class="is-flex is-align-items-flex-end">
          <div v-if="isFromBS" class="mt-3 is-flex gap-3">
            <ButtonApp :is-bold="true" :isSecondary="true" v-if="isSM || isBS"
            @click="dataSurvey.detail.worker_id ?
            handleOpenModalsCantCancelSurvey() : handleOpenModalsCancelSurvey()">
              <p style="padding: 5px">Batalkan Survey</p>
            </ButtonApp>
            <ButtonApp :is-bold="true" @click="handleUpdateReschedule">
              <p style="padding: 5px">Reschedule Survey Proyek</p>
            </ButtonApp>
          </div>

          <div v-if="dataHeader.button_offering_expired" class="ml-2">
            <ButtonApp :isSecondary="true" :is-bold="true" @click="handleExpired">
              <p style="padding: 5px">Jadikan Penawaran Expired</p>
            </ButtonApp>
          </div>

          <div v-if="dataHeader.button_offering_client" class="ml-2">
            <ButtonApp :is-bold="true" @click="isSMRC ? handleModalLihatPenawaran(true) : onPreviewPenawaranClient(true)">
              <p style="padding: 5px">Lihat Penawaran Client</p>
            </ButtonApp>
          </div>

          <div v-if="isOffering" class="ml-2">
            <div v-if="dataPenawaran.can_create_project === true">
              <ButtonApp :is-bold="true" @click="onCreateOrderProject">
                <p style="padding: 5px">Buat Order Proyek</p>
              </ButtonApp>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Lhat Penawaran -->
      <div v-if="isSMRC">
        <b-modal v-model="modalLihatPenawaran">
          <div class="modalLihatPenawaran">
            <div class="header-modal">
              <h5>Lihat Penawaran</h5>
              <div  class="clickable" @click="handleModalLihatPenawaran(false)">
                <XMarkIcon />
              </div>
            </div>
            <div class="body-modal">
              <p class="mb-5">Pilih salah satu format penawaran untuk ditampilkan!</p>
              <div @click="tipeOffering('price-actual')" class="notification clickable is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-light border-radius-20 overflow-hidden border-2 c-w-100">
                <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100">
                 <div style="gap: 1rem" class="is-flex is-flex-direction-row is-justify-content-flex-start c-w-85">
                   <img src="@/assets/img/ActualPriceIcon.svg" alt="ActualPriceIcon" />
                   <div class="is-flex is-flex-direction-column is-justify-content-center">
                    <h5 class="averta-bold">Harga Dasar</h5>
                    <p class="averta-regular">Tampilan ini adalah detail penawaran yang disajikan oleh Kanggo untuk Client </p>
                   </div>
                 </div>
                 <div class="is-flex is-flex-direction-row is-justify-content-flex-end clickable">
                    <ArrowRightIcon />
                 </div>
                </div>
              </div>

              <div @click="tipeOffering('price-tenant')" class="notification clickable is-flex is-flex-direction-column is-justify-content-center is-align-items-center is-light border-radius-20 overflow-hidden border-2 c-w-100">
                <div class="is-flex is-flex-direction-row is-justify-content-space-between is-align-items-center c-w-100">
                 <div style="gap: 1rem" class="is-flex is-flex-direction-row is-justify-content-flex-start c-w-85">
                   <img src="@/assets/img/TetantPriceIcon.svg" alt="ActualPriceIcon" />
                   <div class="is-flex is-flex-direction-column is-justify-content-center">
                    <h5 class="averta-bold">Harga Tenant</h5>
                    <p class="averta-regular">Tampilan ini adalah detail penawaran yang disajikan Client Kanggo untuk Customer/Tenant</p>
                   </div>
                 </div>
                 <div class="is-flex is-flex-direction-row is-justify-content-flex-end clickable">
                    <ArrowRightIcon />
                 </div>
                </div>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
      <!-- Modal Lhat Penawaran -->

      <!-- Group ID Survey & Proyek -->
      <SurveyProjectID :dataHeaderProps="dataHeader" @onModalProyekDarurat="handleProyekDarurat($event)" />

      <!-- Konsultan -->
      <div v-if="isKonsultan">
        <CardSurveyProcess class="my-5" v-if="dataHeader.status == 'Survey Berlangsung' && dataHeader.survey_date === dateNow && isBeforeTime(dataHeader.start_time)" />
        <CardSurveyLate class="my-5" v-if="dataHeader.status == 'Survey Berlangsung' && dataHeader.survey_date < dateNow" />
        <CardPenawaranPending class="my-5" v-if="dataHeader.status == 'Penawaran Ditinjau'" />
        <CardReviewOffering class="my-5" :confirmBy="confirmByRole" v-if="dataHeader.status == 'Menunggu Konfirmasi'" />
        <CardSurveyLate2 v-if="dataHeader.status == 'Survey Berlangsung' && (dataHeader.attendance_start_date === '-' || dataHeader.attendance_start_date === null) && isMoreThanTenMinutes(dataHeader.start_time) && dataHeader.survey_date === dateNow" />
      </div>

      <!-- SM -->
      <div v-if="isSM">
        <CardWaitingConfirmation class="my-5" v-if="dataHeader.substatus === 'waiting-sm'" />
      </div>

      <!-- BS -->
      <div v-if="isBS">
        <CardPenawaranPending class="my-5" v-if="dataHeader.status == 'Penawaran Ditinjau'" />
        <CardRejected class="my-5" v-if="dataHeader.status == 'Penawaran Ditolak'" :dataPenawaran="dataPenawaran" @updateData="loadDataSurvey()" :dataHeader="dataHeader" />
        <CardSurveySelesaiAM class="my-5" v-if="dataHeader.status == 'Survey Selesai'" />
        <CardLengkapiPenawaranAM class="mb-5" v-if="isOffering > 0 && dataPenawaran.can_complete_offering == true" :offeringId="dataPenawaran.id" :dataHeader="dataHeader" />
        <CardLampiranSIKSPK v-if="isOffering > 0 && dataPenawaran.sik_spk.length == 0 && dataHeader.status == 'Penawaran Disetujui'" />
        <CardUploadSIKSPK v-if="isOffering > 0 && dataPenawaran.is_attachment_uploaded === true && dataPenawaran.sik_spk.length > 0" :dataDocument="isSMRC ? [] : dataPenawaran.sik_spk" />
      </div>

      <!-- SM & BS -->
      <div v-if="isSM || isBS">
        <div class="divider mb-5"></div>
        <CardRescheduleDetailSurvey v-if="isFromClient" :data-reschedule="dataHeader.reschedule_request" :handle-reject-reschedule="handleRejectReschedule" :handle-update-reschedule="handleUpdateReschedule" :isSMRC="isSMRC" />
      </div>

      <ModalProyekDarurat :dataModalUrgent="dataHeader.project_urgent_id" :surveyId="dataHeader.id" :showModal="modalProyekDarurat" :loadData="loadDataSurvey" :width="isMobile ? '95vw' : '45vw'" @closeModal="handleProyekDarurat(false)" />

      <ModalAlertCantCancelSurvey
        :isHandleOpenModalAlertCancelSurvey="isHandleOpenModalAlertCancelSurvey"
        @closeModalAlertCantCancelSurvey="closeModalAlertCantCancelSurvey"
      />

      <ModalCancelSurvey
        :isHandleOpenModalCancelSurvey="isHandleOpenModalCancelSurvey"
        :dataSurvey="dataProps"
        @closeModalCancelSurvey="closeModalCancelSurvey"
      />

    </div>

    <b-loading :is-full-page="true" v-model="isLoadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>

  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { getStatusClassName, wordingMenuHelper, isSMRC, alertErrorMessage, toastMessage } from '@/helpers'

// Assets
import confirmOfferingImage from '@/assets/img/delete-area-component.png'
import successCreateImage from '../../../assets/img/sunset.png'
import { ArrowLeftIcon, TagRedirect, ArrowRightIcon, XMarkIcon, SpinnerIcon } from '@/components/icons'

// Component
import ButtonApp from '@/components/button/ButtonApp.vue'
import RedirectClient from '@/components/RedirectClient'
import SurveyProjectID from './SurveyProjectID.vue'
import ModalProyekDarurat from '@/components/modal/ModalProyekDarurat.vue'

// Konsultan
import CardSurveyProcess from '@/components/card/CardSurveyProcess'
import CardSurveyLate from '@/components/card/CardSurveyLate'
import CardSurveyLate2 from '@/components/card/CardSurveyLate2'
import CardPenawaranPending from '@/components/card/CardPenawaranPending'
import CardReviewOffering from '@/components/card/CardReviewOffering'
import CardRescheduleDetailSurvey from '@/components/card/CardRescheduleDetailSurvey.vue'

// SM
import CardWaitingConfirmation from '@/views/v2/sm/card/CardWaitingConfirmation.vue'

// BS
import CardRejected from '@/components/card/CardRejected'
import CardSurveySelesaiAM from '@/components/card/CardSurveySelesaiAM.vue'
import CardLengkapiPenawaranAM from '@/components/card/CardLengkapiPenawaranAM.vue'
import CardLampiranSIKSPK from '@/components/card/CardLampiranSIKSPK.vue'
import CardUploadSIKSPK from '@/components/card/CardUploadSIKSPK.vue'

// Modal
import ModalAlertCantCancelSurvey from '@/components/modal/ModalAlertCantCancelSurvey.vue'
import ModalCancelSurvey from '@/components/modal/ModalCancelSurvey.vue'

export default {
  name: 'HeaderComponent',
  props: {
    dataProps: {
      type: Object,
      required: true
    },
    dataPenawaran: {
      type: Object,
      required: true
    },
    // Check Later
    isProject: {
      type: Boolean,
      default: false
    },
    isOffering: {
      type: Boolean,
      default: false
    },
    loadDataSurvey: {
      type: Function
    }
  },
  components: {
    ArrowLeftIcon,
    TagRedirect,
    ArrowRightIcon,
    ButtonApp,
    RedirectClient,
    SurveyProjectID,
    ModalProyekDarurat,
    XMarkIcon,
    SpinnerIcon,
    // Konsultan
    CardSurveyProcess,
    CardSurveyLate,
    CardPenawaranPending,
    CardReviewOffering,
    CardSurveyLate2,
    CardRescheduleDetailSurvey,
    // SM
    CardWaitingConfirmation,
    // BS
    CardRejected,
    CardSurveySelesaiAM,
    CardLengkapiPenawaranAM,
    CardLampiranSIKSPK,
    CardUploadSIKSPK,
    // Modal
    ModalAlertCantCancelSurvey,
    ModalCancelSurvey
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user',
      heightNavbar: 'dashboard/getNavbarHeight',
      dataSurvey: 'project/getDataDetailSurvey'
    }),
    dateNow () {
      return moment().format('YYYY-MM-DD')
    },
    currentPath () {
      const path = this.$route.path.split('/')
      const firstElement = path[1]

      return firstElement
    },
    isBS () {
      if (this.roleName === 'Account Manager' || this.roleName === 'Business Support') {
        return true
      }
      return false
    },
    isSM () {
      if (this.roleName === 'Site Manager') {
        return true
      }
      return false
    },
    isKonsultan () {
      if (this.roleName === 'Konsultan Survey') {
        return true
      }
      return false
    },
    isFromClient () {
      if (this.dataHeader.can_reschedule && this.dataHeader.reschedule_request) return true
      return false
    },
    isFromBS () {
      if (this.dataHeader.can_reschedule && !this.dataHeader.reschedule_request) return true
      return false
    },
    isMobile () {
      if (window.innerWidth <= 768) {
        return true
      }
      return false
    },
    isSMRC () {
      return isSMRC(this.dataHeader.vendor.code || '')
    },
    showPenawaranClient () {
      const status = this.dataHeader.status

      if (
        this.isOffering && (
        // Old
          status === 'Penawaran Ditinjau' ||
        status === 'Penawaran Disetujui' ||
        status === 'Penawaran Approved' ||
        status === 'Penawaran Ditolak' ||
        status === 'Penawaran Rejected' ||

        // New
        status === 'Menunggu Konfirmasi Client' ||
        status === 'Penawaran Disetujui Client')
      ) {
        return true
      }
      return false
    },
    conditionalIDProject () {
      if (this.dataHeader.project_complain && this.dataHeader.project_complain.survey_complain_code) {
        return this.dataHeader.project_complain.survey_complain_code
      } else {
        return `${this.dataHeader.id}`
      }
    }
  },
  data () {
    return {
      roleName: null,
      dataHeader: null,
      confirmByRole: null,
      modalProyekDarurat: false,
      navbarPosition: null,
      modalLihatPenawaran: false,
      isLoadingSpinner: false,

      // SM
      canSubmit: false,
      canUpdate: false,
      // Modal
      isHandleOpenModalAlertCancelSurvey: false,
      isHandleOpenModalCancelSurvey: false
    }
  },
  methods: {
    convertDate (date) {
      if (!date) {
        return ''
      }
      return moment(date).format('dddd, DD MMMM YYYY')
    },
    goBack () {
      const queryParams = this.$route.query

      if (queryParams && queryParams.fromSearch) {
        return this.$router.go(-1)
      }

      if (this.currentPath === 'am') {
        this.$router.push('/am/project')
      } else if (this.currentPath === 'sm') {
        this.$router.push('/sm/project')
      } else {
        this.$router.push('/project')
      }
    },
    handleGetStatusClass (status) {
      return getStatusClassName(status)
    },
    handleRejectReschedule () {
      this.$emit('rejectReschedule')
    },
    handleUpdateReschedule () {
      this.$emit('updateReschedule')
    },
    handleUpdatePenawaran () {
      this.$router.push(`/sm/project/${this.dataHeader.id}/update-offering${this.dataHeader.vendor.id ? `?source=${this.dataHeader.vendor.id}` : ''} `)
      localStorage.removeItem('stepData')
    },
    handleProyekDarurat (props = {}) {
      const { value } = props
      this.modalProyekDarurat = value
    },
    handleScroll () {
      const stickyHeader = document.querySelector('.sticky-header')
      const limitScroll = this.isMobile ? 130 : 200

      if (window.scrollY > limitScroll) {
        stickyHeader.classList.add('is-pinned')
        setTimeout(() => {
          stickyHeader.classList.add('transition')
        }, 1)
        if (this.isMobile) {
          stickyHeader.classList.add('mobile')
        }
      } else {
        stickyHeader.classList.remove('is-pinned')
        stickyHeader.classList.remove('transition')

        if (this.isMobile) {
          stickyHeader.classList.remove('mobile')
        }
      }
    },
    onCheckin () {
      // this.$router.push(`/project/${this.dataHeader.id}/checkin-pemeriksaan`)
      this.$router.push(`/project/${this.dataHeader.id}/checkin-pemeriksaan${this.dataHeader.detail.vendor_id ? `?source=${this.dataHeader.detail.vendor_id}` : ''}`)
      localStorage.removeItem('stepData')
    },
    onFinishSurvey () {
      this.$emit('modalSurveySelesai', true)
    },
    onCreateOffering () {
      this.$router.push(`/project/${this.dataHeader.id}/new-offering${this.dataHeader.detail.vendor_id ? `?source=${this.dataHeader.detail.vendor_id}` : ''}`)
      localStorage.removeItem('stepData')
    },
    onCreateOrderProject () {
      this.$router.push(`/am/project/offering/${this.dataPenawaran.id}/order`)
      localStorage.removeItem('stepData')
    },
    onProceedToPurchasing () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjutkan Penawaran Ke Purchasing?</p>',
        html: '<p class="averta-regular">Pastikan detail penawaran telah sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Kirim ke Purchasing',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        },
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId
          }

          this.$store
            .dispatch('projectV2/createPenawaran', payload)
            .then(() => {
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran Berhasil Dilanjutkan</p>
                  <p>Penawaran akan direview dan dilengkapi oleh Purchasing</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Lihat Detail',
                cancelButtonText: 'Lihat Daftar',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  this.loadDataSurvey()
                } else {
                  this.$router.push('/sm/project')
                }
              })
            })
            .catch((error) => {
              console.log(error)
              this.$buefy.toast.open({
                message: '<p class="averta-regular">Gagal melanjutkan penawaran, silahkan coba lagi</p>',
                type: 'is-danger'
              })
            })
        }
      })
    },
    onPreviewPenawaranClient () {
      this.$emit('sidebarPenawaranClient', true)
      localStorage.removeItem('stepData')
    },
    isBeforeTime (time) {
      const currentTime = moment()
      const compareTime = moment(time, 'HH:mm:ss')
      if (currentTime.isBefore(compareTime)) {
        return true
      } else {
        return false
      }
    },
    isMoreThanTenMinutes (time) {
      const startTime = moment(time, 'HH:mm:ss')
      const now = moment()
      const diffMinutes = now.diff(startTime, 'minutes')

      if (diffMinutes >= 10) {
        return true
      } else {
        return false
      }
    },
    loadData () {
      const temp = {
        id: this.dataProps.id,
        status: this.dataProps.status,
        vendor: this.dataProps.vendor
      }
      if (this.dataProps.client_external_id) {
        temp.client_external_id = this.dataProps.client_external_id
      }
      if (this.isProject) {
        // Project
        temp.store_name = this.dataProps.name
        temp.offering_number = this.dataProps.offering_number
        temp.created_at = this.dataProps.created_at
        // temp.branch_name = Response Not Ready. Cabang is Required
      } else {
        // Survey
        temp.store_name = this.dataProps.store.name
        temp.branch_name = this.dataProps.store.branch
        temp.start_time = this.dataProps.start_time
        temp.survey_date = this.dataProps.survey_date
        temp.attendance_start_date = this.dataProps.detail.attendance_start_date
        temp.can_check_in = this.dataProps.can_check_in
        temp.can_complete_survey = this.dataProps.can_complete_survey
        temp.can_create_offering = this.dataProps.can_create_offering
        temp.substatus = this.dataProps.substatus
        temp.detail = this.dataProps.detail
        temp.can_reschedule = this.dataProps.can_reschedule
        temp.reschedule_request = this.dataProps.reschedule_request
        temp.project_urgent_id = this.dataProps.project_urgent_id
        temp.survey_homecare_id = this.dataProps.survey_homecare_id
        temp.project_homecare_id = this.dataProps.project_homecare_id
        temp.project_complain = this.dataProps.project_complain

        // Offering
        if (this.isOffering) {
          temp.offering_number = this.dataPenawaran.offering_number || this.dataProps.card_information.offering_number
          temp.created_at = this.dataPenawaran.created_at || this.dataPenawaran.card_information.created_at
          temp.tooltips = this.dataPenawaran.tooltips
          this.confirmByRole = this.dataPenawaran.status_bar
          temp.button_offering_client = this.dataPenawaran.button_offering_client
          temp.client_type_code = this.dataPenawaran.client_type_code
          temp.client_type_tag = this.dataPenawaran.client_type_tag
          temp.button_offering_expired = this.dataPenawaran.button_offering_expired

          // SM
          this.canUpdate = this.dataPenawaran.can_update
          this.canSubmit = this.dataPenawaran.can_submit
        }
      }
      this.dataHeader = temp
    },
    wordingHelper (value) {
      return wordingMenuHelper(value)
    },
    adjustStickyPosition () {
      setTimeout(() => {
        if (this.heightNavbar) {
          this.navbarPosition = this.heightNavbar + 'px'
        }
      }, 600)
    },
    redirectToProjectParent () {
      if (this.isBS) {
        const routeData = this.$router.resolve({ path: `/am/project/${this.dataHeader.project_complain.project_parent_id}/detail` })
        window.open(routeData.href, '_blank')
      } else if (this.isSM) {
        const routeData = this.$router.resolve({ path: `/sm/project/${this.dataHeader.project_complain.project_parent_id}/detail` })
        window.open(routeData.href, '_blank')
      }
    },
    handleModalLihatPenawaran (value) {
      this.modalLihatPenawaran = value
      localStorage.removeItem('stepData')
    },
    tipeOffering (type) {
      this.$emit('selectedTipeOffering', type)
      this.onPreviewPenawaranClient()
      this.handleModalLihatPenawaran(false)
      localStorage.removeItem('stepData')
    },
    handleOpenModalsCancelSurvey () {
      this.isHandleOpenModalCancelSurvey = true
    },
    closeModalCancelSurvey () {
      this.isHandleOpenModalCancelSurvey = false
    },
    handleOpenModalsCantCancelSurvey () {
      this.isHandleOpenModalAlertCancelSurvey = true
    },
    closeModalAlertCantCancelSurvey () {
      this.isHandleOpenModalAlertCancelSurvey = false
    },
    handleExpired () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Jadikan Penawaran Expired</p>',
        html: '<p class="averta-regular">Penawaran akan menjadi expired dan tidak dapat dilanjutkan</p>',
        showCancelButton: true,
        confirmButtonText: 'Jadikan Expired',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        },
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.isLoadingSpinner = true
          const payload = {
            url: this.url,
            offering_id: this.dataPenawaran.id
          }

          this.$store
            .dispatch('project/expiredOffering', payload)
            .then(() => {
              this.loadDataSurvey()
              this.isLoadingSpinner = false
              toastMessage('Penawaran Telah Expired', 'is-dark')
            })
            .catch((error) => {
              this.isLoadingSpinner = false
              console.log(error)
              alertErrorMessage(error)
            })
        }
      })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
    this.adjustStickyPosition()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    this.loadData()
    this.roleName = this.user.roles[0].name
  },
  watch: {
    dataProps () {
      this.loadData()
    },
    dataPenawaran () {
      this.loadData()
    },
    heightNavbar (val) {
      if (val) {
        this.adjustStickyPosition()
      }
    }
  }
}
</script>

<style scoped>
.tag-redirect {
  background: #FAFAFA;
  width: max-content;
  height: 36px;
  border-radius: 100px;
  border: 1px solid #E1E1E1;
  padding: 8px 12px;
}

.tag-redirect.is-disabled {
  cursor: not-allowed;
}
.modalLihatPenawaran{
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 757px;
  max-width: 757px;
  height: 450px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  overflow: hidden;
  border-radius: 20px;
}
.header-modal{
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 50px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2rem;
  background-color: #B40007;
}
.header-modal > h5{
  color: #FFFFFF;
  font-size: 18px;
  font-weight: 700;
}
.body-modal{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  align-items: flex-start;
  margin: 0 auto;
  padding: 2rem;
  gap: 0rem;
  background-color: #FFFFFF;
}
.clickable{
  cursor: pointer !important;
}
</style>
