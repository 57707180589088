var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-table-unit-price"},[(_vm.initialData.length > 0)?_c('div',{staticClass:"is-flex unit-price-container"},[_c('div',{staticClass:"unit-price-section left"},[_c('div',{staticClass:"is-flex bg-dark-grey-5 color-white averta-bold br-note-item-6"},[_c('div',{staticClass:"item-price left",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_NO)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',[_vm._v("NO")])]),_c('div',{staticClass:"item-price left",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_ID)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',[_vm._v("ID")])]),_c('div',{staticClass:"item-price left",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_NOTE_ITEM)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',[_vm._v("NAMA ITEM PEKERJAAN")])]),_c('div',{staticClass:"item-price left",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_UNIT_NAME)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',[_vm._v("SATUAN")])])]),_vm._l((_vm.dataTable),function(item,index){return _c('div',{key:index,staticClass:"item-column br-note-item-6"},[_c('div',{staticClass:"flex-center",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_NO)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',[_vm._v(_vm._s(index+1))])]),_c('div',{staticClass:"flex-center",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_ID)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',{staticClass:"averta-bold"},[_vm._v(_vm._s(item.id))])]),_c('div',{style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_NOTE_ITEM)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('div',{staticClass:"flex-center c-h-100"},[_c('div',{staticClass:"max-c-h-100 c-w-100"},[_c('p',[_vm._v(_vm._s(item.note))])])])]),_c('div',{staticClass:"flex-center",style:({
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_UNIT_NAME)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',[_vm._v(_vm._s(item.name))])])])})],2),_c('div',{staticClass:"unit-price-section middle"},[_c('div',{staticClass:"is-flex color-white averta-bold"},[_c('div',{staticClass:"item-price middle flex-center bg-dark-grey-5",style:({
            minWidth: ((_vm.S.TBL_WIDTH_MATERIAL) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_MATERIAL)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',{staticClass:"has-text-centered"},[_vm._v("HARGA MATERIAL")]),_c('div',{staticClass:"is-clickable ml-1",on:{"click":function($event){return _vm.handleSort('material_price')}}},[_c('img',{attrs:{"src":require('@/assets/icons/arrow-top-bottom-used.svg'),"alt":""}})])]),_c('div',{staticClass:"item-price middle flex-center bg-dark-grey-5",style:({
            minWidth: ((_vm.S.TBL_WIDTH_JASA) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_JASA)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',[_vm._v("HARGA JASA")]),_c('div',{staticClass:"is-clickable ml-1",on:{"click":function($event){return _vm.handleSort('service_price')}}},[_c('img',{attrs:{"src":require('@/assets/icons/arrow-top-bottom-used.svg'),"alt":""}})])]),_c('div',{staticClass:"item-price middle flex-center bg-dark-grey-5",style:({
            minWidth: ((_vm.S.TBL_WIDTH_SATUAN) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_SATUAN)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_c('p',{staticClass:"has-text-centered"},[_vm._v("HARGA SATUAN")]),_c('div',{staticClass:"is-clickable ml-1",on:{"click":function($event){return _vm.handleSort('unit_price')}}},[_c('img',{attrs:{"src":require('@/assets/icons/arrow-top-bottom-used.svg'),"alt":""}})])]),_c('div',{staticClass:"item-price middle flex-center bg-dark-grey-5",style:({
            minWidth: ((_vm.S.TBL_WIDTH_UPDATED_AT) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_UPDATED_AT)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })},[_vm._m(0),_c('div',{staticClass:"is-clickable ml-1",on:{"click":function($event){return _vm.handleSort('last_updated_at')}}},[_c('img',{attrs:{"src":require('@/assets/icons/arrow-top-bottom-used.svg'),"alt":""}})])]),_c('div',{staticClass:"item-price middle bg-dark-grey-5",style:({
            minWidth: ((_vm.S.TBL_WIDTH_ACTION) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_ACTION)),
            height: ((_vm.S.HEIGHT_COLUMN_HEADER) + "px")
          })})]),_vm._l((_vm.dataTable),function(item,index){return _c('div',{key:index,staticClass:"is-flex"},[_c('div',{staticClass:"item-price middle is-justify-content-space-between averta-bold",style:({
            minWidth: ((_vm.S.TBL_WIDTH_MATERIAL) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_MATERIAL)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',[_vm._v("Rp")]),_c('p',[_vm._v(_vm._s(item.material_price ? _vm.formatThousand(item.material_price) : 0))])]),_c('div',{staticClass:"item-price middle is-justify-content-space-between averta-bold",style:({
            minWidth: ((_vm.S.TBL_WIDTH_JASA) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_JASA)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',[_vm._v("Rp")]),_c('p',[_vm._v(_vm._s(item.service_price ? _vm.formatThousand(item.service_price) : 0))])]),_c('div',{staticClass:"item-price middle is-justify-content-space-between averta-bold",style:({
            minWidth: ((_vm.S.TBL_WIDTH_SATUAN) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_SATUAN)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',[_vm._v("Rp")]),_c('p',[_vm._v(_vm._s(_vm.formatThousand(item.unit_price)))])]),_c('div',{staticClass:"item-price middle",style:({
            minWidth: ((_vm.S.TBL_WIDTH_UPDATED_AT) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_UPDATED_AT)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('p',[_vm._v(_vm._s(_vm.formatDate(item.last_updated_at)))])]),_c('div',{staticClass:"item-price middle flex-center",style:({
            minWidth: ((_vm.S.TBL_WIDTH_ACTION) + "px"),
            width: ("" + (_vm.S.TBL_WIDTH_PERCENT_ACTION)),
            height: ((_vm.S.TBL_HEIGHT_COLUMN_BODY) + "px")
          })},[_c('div',{staticClass:"is-clickable mr-3",on:{"click":function () { return _vm.handleEdit(item); }}},[_c('EditIcon',{attrs:{"color":"black","size":"18"}})],1),_c('div',{staticClass:"is-clickable",on:{"click":function () { return _vm.handleDelete(item); }}},[_c('TrashIcon',{attrs:{"size":"18"}})],1)])])})],2),_c('b-loading',{attrs:{"is-full-page":true,"can-cancel":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}},[_c('SpinnerIcon')],1)],1):_c('div',{staticClass:"column c-w-100 has-text-centered"},[_c('ProyekEmptyIcon'),_c('p',{staticClass:"averta-bold mt-2"},[_vm._v(" Data Tidak Ditemukan ")])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"has-text-centered"},[_vm._v("TANGGAL "),_c('br'),_vm._v(" TER UPDATE")])}]

export { render, staticRenderFns }